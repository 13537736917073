<template>
    <admin>
        <page-header :back="{ to: { name: 'survey' }, text: trans('Survey') }"></page-header>
        <p v-show="isCreatingQuestion"> <a @click="isCreatingQuestion = false;">< Back</a></p>
        <div  v-if="!isCreatingQuestion">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card class="mb-5">
                    <v-card-title>Edit Response</v-card-title>
                    <v-card-text>     
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Customer Name"
                                    class="dt-text-field"
                                    outlined
                                    disabled
                                    v-model="form.customer_name"
                                    :rules="rules.title"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Survey Name"
                                    class="dt-text-field"
                                    outlined
                                    disabled
                                    v-model="form.survey.survey_name"
                                    :rules="rules.slug"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Root Cause"
                                    class="dt-text-field"
                                    outlined
                                    v-model="form.root_cause"
                                    :rules="rules.slug"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-textarea
                                    name="input-7-1"
                                    label="Action Taken"
                                    class="dt-text-area"
                                    outlined
                                    v-model="form.action_taken"
                                    :rules="rules.description"
                                ></v-textarea>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                            <v-select
                                item-text="text"
                                item-value="status"
                                :items="status_items"
                                outlined
                                v-model="defaultSelected"
                                label="Status"
                            ></v-select>
                            </v-col>
                        </v-row>
                    
                    </v-card-text>
                </v-card>
                <div class="d-flex justify-end mt-6">
                    <v-btn
                        large
                        exact
                        color="green darken-1"
                        class="ma-1 white--text"
                        :loading="loadingSave"
                        :disabled="loadingSave || loadingSaveAndExit"
                        @click="validate(false)"
                    >
                    <v-icon left>mdi-content-save</v-icon>
                        Save
                    </v-btn>
                    <v-btn
                        large
                        exact
                        color="green lighten-1"
                        class="ma-1 white--text"
                        :loading="loadingSaveAndExit"
                        :disabled="loadingSave || loadingSaveAndExit"
                        @click="validate(true)"
                    >
                    <v-icon left>mdi-content-save</v-icon>
                        Save and Exit
                    </v-btn>
                    <v-btn
                        large
                        exact
                        color="warning"
                        class="ma-1 white--text"
                        :to="{ name : 'survey'}"
                    >
                    <v-icon left>mdi-close</v-icon>
                        Cancel
                    </v-btn>
                </div>
            </v-form>              
        </div>
    </admin>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    components : {
    },
    data () {
        return {
            defaultSelected: {
              text: "Pending",
              value: 0
            },
            form : {
                customer_name : "",
                survey : "",
                action_taken : "",
                root_cause : "",
                status : true,
                slug : ""
            },
            status_items: [{text: "Pending", value: 0}, {text: "Closed", value: 1}, {text: "Processing", value: 2}],
            isCreatingQuestion : false,
            valid : false,
            rules: {
                title: [(value) => !!value || "Title is required"],
                slug: [(value) => !!value || "Slug is required"],
            },
            defaultSurvey : {},
            loadingSaveAndExit : false,
            loadingSave : false,
        }
    },
    computed : {
        ...mapGetters({
            email_notifications : "survey/GET_EMAIL_NOTIFICATION"
        })
    },
    methods : {
        ...mapActions({
            // getSurveys : "survey/getSurveys"
        }),
        toggleViewQuestion() {
            this.isCreatingQuestion = true
        },
        validate(isExit) {
            const isValid = this.$refs.form.validate();

            if(!isValid) return;

            if(isExit) {
                this.loadingSaveAndExit = true;
                setTimeout(() => {
                    this.$router.push({ name : "survey" })
                }, 1500)
            } else {
                this.loadingSave = true;
            }
        }
    },
    mounted() {
        let id = this.$route.params.id;
        const filteredData = this.email_notifications.find(x => x.id == id);
        this.form.customer_name = filteredData.customer_name;
        this.form.survey = filteredData.survey_data;
        this.form.action_taken = filteredData.action_taken;
        this.form.root_cause = filteredData.root_cause;
        this.form.status = filteredData.status.value;
        this.defaultSurvey = filteredData.question_data;
    }
}

</script>